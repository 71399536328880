// in src/sedes.js
import React from 'react';
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    BooleanField,
    Filter,
    TextInput,
    ReferenceField,
    Show,
    SimpleShowLayout,
    ShowButton,
    FunctionField,
    ChipField
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import {CopyData} from "./App";

const SedesFilter = (props) => (
    <Filter {...props} perPage={-1}>
        <TextInput label="Buscar..." source="q" alwaysOn />
    </Filter>
);
export const SedesShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="nombre" />
        </SimpleShowLayout>
    </Show>
);

export const SedesList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
    <List filters={<SedesFilter />} >
        {isSmall ? (
                <SimpleList
                    primaryText={record => record.nombre}
                    secondaryText={record => record.id}
                    tertiaryText={record => record.activo}
                />
        ) : (
            <Datagrid>

                <div label="ID">
                    <TextField source="id"/>
                    <CopyData field="id"></CopyData>
                </div>

                <div label="Nombre">
                    <TextField source="nombre"/>
                    <CopyData field="nombre"></CopyData>
                </div>

                <BooleanField source="activo"/>
                <ReferenceField label="Concesionario" source="concesionario" reference="concesionarios" link="show">
                    <FunctionField render={(record) => (
                        <ChipField source="nombre"
                                   color={record.activo ? 'success' : 'error'}
                                   icon={record.activo ? <DoneIcon/> : <ClearIcon/>}/>
                    )}/>
                </ReferenceField>
                <ReferenceField label="Cliente" source="cliente" reference="clientes" link="show">
                    <FunctionField render={(record) => (
                        <ChipField source="nombre"
                                   color={record.activo ? 'success' : 'error'}
                                   icon={record.activo ? <DoneIcon/> : <ClearIcon/>}/>
                    )}/>
                    {/* <TextField source="nombre" /> */}
                </ReferenceField>
                <ShowButton/>
            </Datagrid>
        )}
    </List>
    );
};