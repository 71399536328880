// in src/inboxparser.js
import React from 'react';
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    Create,
    Filter,
    CloneButton,
    FormDataConsumer
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import {CopyData} from "./App";

const InboxparserFilter = (props) => (
    <Filter {...props} perPage={-1}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Cliente" source="id_clientes" reference="clientes" allowEmpty perPage={-1}>
            <SelectInput optionText="nombre" />
        </ReferenceInput>
    </Filter>
);

const InboxparserTitle = ({ record }) => {
    return <span>Website {record ? `"${record.title}"` : ''}</span>;
};

export const InboxparserList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
    <List filters={<InboxparserFilter />} >
        {isSmall ? (
            <SimpleList
            primaryText={record => record.nombre}
            secondaryText={record => record.web}
            tertiaryText={record => record.id_sedes}
            />
            ) : (

            <Datagrid>

                <div label="ID">
                    <TextField source="id"/>
                    <CopyData field="id"></CopyData>
                </div>

                <div label="Nombre">
                    <TextField source="nombre"/>
                    <CopyData field="nombre"></CopyData>
                </div>

                <ReferenceField source="id_clientes" reference="clientes" label="Cliente">
                    <TextField source="nombre"/>
                </ReferenceField>
                <ReferenceField source="concesionarios_lc" reference="concesionarios" label="Concesionario">
                    <TextField source="nombre"/>
                </ReferenceField>
                <ReferenceField source="sedes_lc" reference="sedes" label="Sede">
                    <TextField source="nombre"/>
                </ReferenceField>
                {/* <TextField source="id_sedes" />
                    <MyUrlField source="web" /> */}
                <EditButton/>
                <CloneButton/>
            </Datagrid>
        )}
    </List>
    );
};

export const InboxparserEdit = props => (
    <Edit title={<InboxparserTitle/>} {...props}>
        <SimpleForm>
        <TextInput disabled source="id" />
            <TextInput source="nombre" />
            <ReferenceInput source="id_clientes" reference="clientes" perPage={-1} filter={{ activo: true }}>
                <SelectInput optionText="nombre" resettable={true} label="Cliente" />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ReferenceInput source="concesionarios_lc" reference="concesionarios" perPage={-1} filter={{ cliente: formData.id_clientes, activo: true }}>
                        <SelectInput optionText="nombre" resettable={true} label="Concesionario" />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ReferenceInput source="sedes_lc" reference="sedes" perPage={-1} filter={{ concesionario: formData.concesionarios_lc, activo: true }}>
                        <SelectInput optionText="nombre" resettable={true} label="Sede" />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
           {/* <TextInput source="id_sedes" /> */}
           {/* <TextInput source="web" /> */}
        </SimpleForm>
    </Edit>
);

export const InboxparserCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="nombre" />
            <ReferenceInput source="id_clientes" reference="clientes" perPage={-1} filter={{ activo: true }}>
                <SelectInput optionText="nombre" resettable={true} label="Cliente" />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ReferenceInput source="concesionarios_lc" reference="concesionarios" perPage={-1} filter={{ cliente: formData.id_clientes, activo: true }}>
                        <SelectInput optionText="nombre" resettable={true} label="Concesionario" />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ReferenceInput source="sedes_lc" reference="sedes" perPage={-1} filter={{ concesionario: formData.concesionarios_lc, activo: true }}>
                        <SelectInput optionText="nombre" resettable={true} label="Sede" />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);