import React, {cloneElement} from 'react';
import {Admin, Resource, useRecordContext, useNotify} from 'react-admin';
import {InboxparserCreate, InboxparserEdit, InboxparserList} from './inboxparser';
import {ClientesList, ClientesShow} from './clientes';
import {ConcesionariosList, ConcesionariosShow} from './concesionarios';
import {SedesList, SedesShow} from './sedes';
import {CategoriasTiposCreate, CategoriasTiposList} from './categoriasTipos';
import {TiposCreate, TiposEdit, TiposList} from './tipos';
import {FieldsLogList} from './fieldLogs';
import {FieldIncluseCreate, FieldIncluseEdit, FieldIncluseList} from './fieldIncluse';
import {FieldExcluseCreate, FieldExcluseList} from './fieldExcluse';
import {UsersCreate, UsersEdit, UsersList, UsersShow} from './users';
import {EstadosCreate, EstadosEdit, EstadosList, EstadosShow} from './estados';
import {LogsCallsList} from './logsCalls';
import jsonServerProvider from 'ra-data-json-server';
import ClientesIcon from '@mui/icons-material/Beenhere';
import ConcesionariosIcon from '@mui/icons-material/Business';
import SedesIcon from '@mui/icons-material/DirectionsCar';
import WebsitesIcon from '@mui/icons-material/List';
import CategoriasTiposIcon from '@mui/icons-material/Folder';
import TiposIcon from '@mui/icons-material/Storage';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import GroupIcon from '@mui/icons-material/Group';
import Dashboard from './Dashboard';
import authProvider from './authProvider';
import {CategoriasLicenciasEdit} from "./categoriasLicencias";
import {LogsAsigList} from "./logsAsig";
import {DataStatusList} from "./dataStatus";
import {LogsAccesos} from "./logsAccesos";
import {LogsEmails} from "./logsEmails";
import {LogsImpresiones} from "./logsImpresiones";
import {LogsLeads} from "./logsLeads";
import {LogsRegApi} from "./logsRegApi";
import {LogsUsers} from "./logsUsers";
import {BillingsEdit, BillingsList, BillingsShow} from "./billings";
import {FailedWebhookList} from "./failedwebhook";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Button from "@mui/material/Button";
import ContentCopy from "@mui/icons-material/ContentCopy";


const urlAPI = `${process.env.REACT_APP_API_URL}lt`;
const dataProvider = jsonServerProvider(urlAPI);

const App = () => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    let isSuperAdmin, isAccount, isSupport;
    if(userData !== null && userData !== ""){
        isSuperAdmin = userData.roles.includes('ROLE_SUPER_ADMIN') || userData.roles.includes('ROLE_ADMIN_LEADTRACKING');
        isSupport = userData.roles.includes('ROLE_SUPPORT');
        isAccount = userData.roles.includes('ROLE_ACCOUNT');
    }

    return (
        <Admin disableTelemetry dashboard={Dashboard} authProvider={authProvider} dataProvider={dataProvider} title="LeadTracking - Menu" requireAuth>
            {isSuperAdmin && <Resource name="dataStatus" list={DataStatusList} options={{ label: 'Datos status leads' }} />}
            {(isSuperAdmin || isSupport || isAccount) && <Resource name="clientes" list={ClientesList} icon={ClientesIcon} show={ClientesShow} />}
            {(isSuperAdmin || isSupport || isAccount) && <Resource name="concesionarios" list={ConcesionariosList} icon={ConcesionariosIcon} show={ConcesionariosShow} />}
            {(isSuperAdmin || isSupport || isAccount) && <Resource name="sedes" list={SedesList} icon={SedesIcon} show={SedesShow} />}
            {(isSuperAdmin || isSupport) && <Resource name="inboxparser" list={InboxparserList} edit={InboxparserEdit} create={InboxparserCreate} icon={WebsitesIcon} />}
            {isSuperAdmin && <Resource name="categoriasTipos" list={CategoriasTiposList} create={CategoriasTiposCreate} icon={CategoriasTiposIcon}  options={{ label: 'Grupos de tipos' }} />}
            {(isSuperAdmin || isSupport) && <Resource name="tipos" list={TiposList} edit={TiposEdit} create={TiposCreate} icon={TiposIcon} options={{ label: 'Tipos de leads' }} />}
            {isSuperAdmin && <Resource name="logkeys" list={FieldsLogList} icon={VpnKeyIcon}  options={{ label: 'Datos registrados' }} />}
            {isSuperAdmin && <Resource name="fieldsincluses" list={FieldIncluseList} create={FieldIncluseCreate} edit={FieldIncluseEdit} icon={LockOpenIcon} options={{ label: 'Campos a incluir' }}  />}
            {isSuperAdmin && <Resource name="fieldsexcluses" list={FieldExcluseList} create={FieldExcluseCreate} icon={LockIcon}  options={{ label: 'Campos a excluir' }} />}
            {(isSuperAdmin || isSupport || isAccount) && <Resource name="users" list={UsersList} create={UsersCreate} show={UsersShow} edit={UsersEdit} icon={GroupIcon}  options={{ label: 'Usuarios' }} />}
            {(isSuperAdmin || isSupport || isAccount) && <Resource name="billings" list={BillingsList} show={BillingsShow} edit={BillingsEdit} icon={GroupIcon}  options={{ label: 'Facturación' }} />}
            {isSuperAdmin && <Resource name="estados" list={EstadosList} show={EstadosShow} create={EstadosCreate} edit={EstadosEdit} icon={GroupIcon}  options={{ label: 'Estados' }} />}
            {isSuperAdmin && <Resource name="categoriasLicencias" edit={CategoriasLicenciasEdit} icon={GroupIcon}  options={{ label: 'Licencias LeadCars' }} />}
            {(isSuperAdmin || isSupport) && <Resource name="logs/accesos/list" list={LogsAccesos} icon={GroupIcon} options={{ label: '[Log] Accesos' }} />}
            {(isSuperAdmin || isSupport) && <Resource name="logs/asig/list" list={LogsAsigList} icon={GroupIcon} options={{ label: '[Log] Asignaciones' }} />}
            {(isSuperAdmin || isSupport) && <Resource name="logs/emails/list" list={LogsEmails} icon={GroupIcon} options={{ label: '[Log] Emails' }} />}
            {(isSuperAdmin || isSupport) && <Resource name="logs/impresiones/list" list={LogsImpresiones} icon={GroupIcon} options={{ label: '[Log] Impresiones' }} />}
            {(isSuperAdmin || isSupport) && <Resource name="logs/calls/list" list={LogsCallsList} icon={GroupIcon} options={{ label: '[Log] Llamadas' }} />}
            {(isSuperAdmin || isSupport) && <Resource name="logs/leads/list" list={LogsLeads} icon={GroupIcon} options={{ label: '[Log] Leads' }} />}
            {(isSuperAdmin || isSupport) && <Resource name="logs/regapi/list" list={LogsRegApi} icon={GroupIcon} options={{ label: '[Log] Reg API' }} />}
            {(isSuperAdmin || isSupport) && <Resource name="logs/users/list" list={LogsUsers} icon={GroupIcon} options={{ label: '[Log] Users Status' }} />}
            {(isSuperAdmin || isSupport) && <Resource name="parsermail" list={FailedWebhookList} icon={GroupIcon} options={{ label: '[Log] Failed Webhook MP' }} />}
        </Admin>
    );
};

export const StringToLabelObject = ({ record, children, ...rest}) =>
    cloneElement(children, {
        record: { label: record},
        ...rest,
    });

    export const CopyData = (value, props) => {
        let field = value.field;
        const record = useRecordContext();
        const notify = useNotify();
        let data = "";
    
        const handleClick = async (data) => {
            notify('Se ha copiado el ' + field, { autoHideDuration: 5000, type: "success" });
        }
    
        data = record[field];
       
        if (!data) {
            return "";
        } else {
            return <CopyToClipboard text={data} >
                <Button onClick={handleClick}><ContentCopy fontSize="10px"></ContentCopy></Button>
            </CopyToClipboard>
        }
    };

export default App;