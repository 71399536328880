// in src/concesionarios.js
import React from 'react';
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    BooleanField,
    Filter,
    TextInput,
    ReferenceField,
    SingleFieldList,
    ChipField,
    ReferenceArrayField,
    Show,
    SimpleShowLayout,
    ShowButton,
    FunctionField
} from 'react-admin';
import {useMediaQuery} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';

import {LoadData} from "./Utils/Services/Edit_services";
import {CopyData} from "./App";

const ConcesionariosFilter = (props) => (
    <Filter {...props} perPage={-1}>
        <TextInput label="Buscar..." source="q" alwaysOn />
    </Filter>
);

export const ConcesionariosShow = () => (
    <Show>

        <SimpleShowLayout>
            <TextField source="nombre" />
        </SimpleShowLayout>

        <LoadData typeData="conce" />

    </Show>
);

// export const CopyData = (action, props) => {
//     action = Object.values(action);
//     const record = useRecordContext();
//     const notify = useNotify();
//     let data = "";

//     const handleClick = async (data) => {
//         notify('Se ha copiado el ' + action, { autoHideDuration: 5000, type: "success" });
//     }

//     if (action == 'id') {
//         data = record.id;
//     }

//     if (action == 'nombre') {
//         data = record.nombre;
//     }

//     if (!data) {
//         return "";
//     } else {
//         return <CopyToClipboard text={data} >
//             <Button onClick={handleClick}><ContentCopy fontSize="10px"></ContentCopy></Button>
//         </CopyToClipboard>
//     }
// }

export const ConcesionariosList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
    <List filters={<ConcesionariosFilter />} >
        {isSmall ? (
                <SimpleList
                    primaryText={record => record.nombre}
                    secondaryText={record => record.id}
                    tertiaryText={record => record.activo}
                />
        ) : (
            <Datagrid>

                <div label="ID">
                    <TextField source="id"/>
                    <CopyData field="id"></CopyData>
                </div>

                <div label="Nombre">
                    <TextField source="nombre"/>
                    <CopyData field="nombre"></CopyData>
                </div>

                <BooleanField source="activo"/>
                <ReferenceField label="Cliente" source="cliente" reference="clientes" link="show">
                    <FunctionField render={(record) => (
                        <ChipField source="nombre"
                                   color={record.activo ? 'success' : 'error'}
                                   icon={record.activo ? <DoneIcon/> : <ClearIcon/>}/>
                    )}/>
                </ReferenceField>
                <ReferenceArrayField label="Sedes" reference="sedes" source='sedes'>
                    <SingleFieldList linkType="show">
                        {/* <ChipField source='nombre' /> */}
                        <FunctionField render={(record) => (
                            <ChipField source="nombre"
                                       variant='outlined'
                                       color={record.activo ? 'success' : 'error'}
                                       icon={record.activo ? <DoneIcon/> : <ClearIcon/>}/>
                        )}/>
                    </SingleFieldList>
                </ReferenceArrayField>
                <ShowButton/>
            </Datagrid>
        )}
    </List>
    );
};