// in src/authProvider.js
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from "react-admin";

const oAuthClientId = "4_2q8yw8wonp6ocgwgokggccow4owgskswsswg8wckos8o80ccg4";
const oAuthClientSecret = "5g0kud6xcscg80gwg48kw0ocgksgs4scso4cggsssokwgs0s0w";
const OAUTH_URL =
    process.env.REACT_APP_OAUTH_URL ?? "https://oauth.leadcars.es/";
const result = (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(OAUTH_URL + 'oauth/v2/token', {
      method: "POST",
      body:
          "grant_type=password&client_id=" +
          oAuthClientId +
          "&client_secret=" +
          oAuthClientSecret +
          "&username=" +
          username +
          "&password=" +
          password,
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    });
    return fetch(request)
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((token) => {
          localStorage.setItem(
              "credentials_token",
              token.token_type + ": " + token.access_token
          );
          localStorage.setItem(
              "credentials_expires",
              Date.now() + token.expires_in * 1000
          );
          localStorage.setItem("credentials_refresh_token", token.refresh_token);
          localStorage.setItem("tokenObject", JSON.stringify(token));
          localStorage.setItem("token", token.access_token);

          const fetchUserData = async (token) => {
            try {
              const response = await fetch(
                  OAUTH_URL + 'user/getUser',
                  {
                    method: "GET",
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
              );
              if (!response.ok) {
                throw new Error("Error al obtener los datos del usuario");
              }

              let userData = await response.json();

              localStorage.setItem("userData", JSON.stringify(userData));
            } catch (error) {
              console.error("Error al obtener los datos del usuario:", error);
            }
          };

          if (token) {
            fetchUserData(token.access_token);
          }

          // window.location.href = '/#';
        })
        .catch(function (error) {
          console.log("Hubo un problema con la petición Fetch:" + error.message);
        });
    // .then(() => {
    //     // Redireccionar a la página de inicio
    //     window.location.href = '/#/clientes';
    // })
  }

  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem("credentials_token");
    localStorage.removeItem("credentials_expires");
    localStorage.removeItem("credentials_refresh_token");
    localStorage.removeItem("token");
    //limpiar mi variable de usuario userData
    localStorage.removeItem("userData");
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    if (localStorage.getItem("credentials_expires") < Date.now()) {
      console.log("Credenciales expiradas");
      localStorage.removeItem("credentials_token");
      localStorage.removeItem("credentials_expires");
      localStorage.removeItem("credentials_refresh_token");
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
    }
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  }
  return Promise.reject("Unknown method");
};

export default result;
